import React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import "../style/Experience.css";
import Resume from "./../public/Tilak_Singh.pdf";
import GradTrans from "./../public/SSR_TSRPT.pdf";
import UndergradTrans from "./../public/Transcript.pdf";
import { handleOpenFile } from "../utils/utils";
import { timeline } from "../utils/info";
import useScrollToTop from "../utils/useScrollToTop";

const FileButtons = ({ buttons }) => {
  useScrollToTop();

  return (
    <div className="file-buttons">
      {buttons.map((button, index) => (
        <Button
          className="file-button"
          key={index}
          variant="outlined"
          onClick={() => handleOpenFile(button.path)}
          style={{ margin: "0 10px 10px 0" }}
        >
          {button.label}
        </Button>
      ))}
    </div>
  );
};

const Experience = () => {
  const experiences = timeline;

  const files = [
    { label: "Resume", path: Resume },
    { label: "Grad Transcript", path: GradTrans },
    { label: "Undergrad Transcript", path: UndergradTrans },
  ];

  return (
    <>
      {/* <div className="banner" style={{ margin: "20px" }}>
        <iframe
          src="https://giphy.com/embed/VbJD13QS9XaqtYchnT"
          width="100%"
          height="100%"
          frameBorder="0"
          className="giphy-embed"
          title="Giphy Embed"
        ></iframe>
      </div> */}

      <h2
        style={{
          textAlign: "center",
          color: "#393f44",
          fontSize: "2.5em",
          marginBottom: "15px",
          lineHeight: "1.2",
          fontWeight: "200",
        }}
      >
        Education & Experiences
      </h2>

      <div className="files">
        <FileButtons buttons={files} />
      </div>

      <Grid
        container
        spacing={1}
        justifyContent="center"
        alignItems="center"
        style={{
          background: "rgb(229,240,241)",
          marginBottom: "80px",
        }}
      >
        {experiences.map((experience, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <div className="experience-card">
              <img
                src={experience.image}
                alt={experience.title}
                className="experience-image"
                onClick={() => handleOpenFile(experience.link)}
              />
              <div class="experience-details">
                <h3 class="position">{experience.position}</h3>
                <div class="details">
                  <p class="org-empType">{`${experience.organization} - ${experience.employmentType}`}</p>
                  <p class="time-location">{`${experience.time} | ${experience.location}`}</p>
                  <p class="description">{experience.description}</p>
                </div>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Experience;
