// Footer.jsx
import React from "react";
import { profileLinks } from "../utils/info";
import { IconButton, Container, Typography } from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import EmailIcon from "@mui/icons-material/Email";
import "../style/Footer.css";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Container className="footer" maxWidth="xxl">
      <div className="icon-container">
        <IconButton
          href="mailto:tilaks484@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <EmailIcon />
        </IconButton>

        <IconButton
          href={profileLinks.Twitter.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <TwitterIcon />
        </IconButton>
        <IconButton
          href={profileLinks.LinkedIn.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkedInIcon />
        </IconButton>
        <IconButton
          href={profileLinks.Github.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <GitHubIcon />
        </IconButton>
      </div>
      <Typography variant="caption" color="textSecondary" align="center">
        Copyright © {currentYear} Tilak Singh. <br />
      </Typography>
    </Container>
  );
};

export default Footer;
