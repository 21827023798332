import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import TopBar from './component/TopBar';
import About from './pages/About';
import Experience from './pages/Experience';
import Projects from './pages/Projects';
import ContactMe from './pages/ContactMe';
import Footer from './component/Footer';
// import Reading from './pages/Reading';
import './style/Footer.css';

function App() {
  return (
    <div className="App">
      <Router>
        <TopBar />
        <Routes>
          <Route path="/experience" element={<Experience />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact-me" element={<ContactMe />} />
          {/* <Route path="/reads" element={<Reading />} /> */}
          <Route path="/" element={<About />} />
        </Routes>

        <Footer/>
      </Router>
    </div>
  );
}

export default App;