// Utility functions


// Can be made to download files instead of opening them in a new tab
export function handleDownload(path, label) {
    const link = document.createElement('a');
    link.href = path;
    link.download = label;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

// Opens the file in a new tab
export function handleOpenFile(path){
    window.open(path, '_blank');
}