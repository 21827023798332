import React, { useState } from 'react';
import { Button, TextField, Container } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import '../style/ContactMe.css';

const ContactMe = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // TODO: Add logic to send the message to your email (e.g., using a backend server)

    // For now, just log the form data to the console
    console.log(formData);
  };

  return (
    <Container className="contact-me-container">
        <h1>Contact Me!</h1>
      <form className="contact-form" onSubmit={handleSubmit}>
        <TextField
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Email"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Message"
          name="message"
          multiline
          rows={4}
          value={formData.message}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <Button type="submit" variant="contained" color="primary" endIcon={<SendIcon />}>
          Send Message
        </Button>
      </form>
    </Container>
  );
};

export default ContactMe;
