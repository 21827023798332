import React from "react";
import { projects, research } from "../utils/info";
import Button from "@mui/material/Button";
import "../style/Projects.css";
import useScrollToTop from "../utils/useScrollToTop";

const Projects = () => {
  useScrollToTop();

  return (
    <div>
      {/* <div className="banner">
        <iframe
          src="https://giphy.com/embed/5eLDrEaRGHegx2FeF2"
          width="100%"
          height="100%"
          frameBorder="0"
          className="giphy-embed"
          allowFullScreen
          title="Giphy Embed"
        ></iframe>
      </div> */}

      <h2
        style={{
          textAlign: "center",
          color: "#393f44",
          fontSize: "2.5em",
          marginBottom: "30px",
          lineHeight: "1.2",
          fontWeight: "200",
        }}
      >
        Projects & Publications
      </h2>
      <div className="project-research">
        <div className="section-container">
          <div className="project-container">
            {projects.map((project, index) => (
              <div className="project-card" key={index}>
                <h4>{project.name}</h4>
                <p style={{ textAlign: "center" }}>{project.description}</p>
                <p style={{ textAlign: "center" }}>
                  <strong>Tech Stack:</strong> {project.techStack.join(", ")}
                </p>
                {project.link && (
                  <Button
                    variant="outlined"
                    href={project.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Project
                  </Button>
                )}
              </div>
            ))}
          </div>
        </div>

        <div className="section-container">
          <div className="research-container">
            {research.slice(0, 2).map((item, index) => (
              <div className="research-card" key={index}>
                <h4>{item.title}</h4>
                <p style={{ textAlign: "center" }}>
                  <strong>Journal:</strong> {item.journal}
                </p>
                <p style={{ textAlign: "center" }}>
                  <strong>Published Date:</strong> {item.publishedDate}
                </p>
                {item.link && (
                  <Button
                    variant="outlined"
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Read More
                  </Button>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
